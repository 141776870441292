import type { CustomElement } from '@integrabeauty/custom-elements';
import { decode } from './decode-html-entities.js';
import html from './index.html';
import styles from './index.scss';

/**
 * Renders circle user avatar with the first letter of the name. Its is used by the
 * yotpo-review-detail element.
 */
class YotpoAvatar extends HTMLElement implements CustomElement {
  readonly dataset!: {
    /**
     * The text that appears adjacent to the badge icon.
     */
    content: string;

    /**
     * The date the question was asked or the date the answer was provided.
     */
    date: string;

    /**
     * The name of the person asking the question/review.
     */
    name: string;

    /**
     * Review score?
     */
    score: string;

    /**
     * Badge type
     */
    type: 'answer' | 'question' | 'review';

    /**
     * Represents whether the person asking the question or providing the answer is a verified
     * person.
     */
    verified: string;
  };

  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
    this.shadowRoot.innerHTML = `<style>${styles}</style>${html}`;
  }

  public connectedCallback() {
    if (this.dataset.name === 'Store Owner') {
      this.shadowRoot.querySelector('.customer-details').classList.add('answer');
    }

    this.render();
  }

  private render() {
    if (this.dataset.date) {
      const date = new Date(this.dataset.date);
      const dateEl = this.shadowRoot.querySelector<HTMLTimeElement>('.customer-info-date');
      dateEl.textContent = date.toLocaleDateString('en-US');
      dateEl.dateTime = date.toISOString().split('T')[0];
    }

    const iconElement = this.shadowRoot.querySelector('.badge-content');
    if (this.dataset.name === 'Store Owner') {
      const storeIcon = document.createElement('i');
      storeIcon.className = 'icon-store';
      iconElement.appendChild(storeIcon);
    } else {
      iconElement.textContent = this.dataset.name ? this.dataset.name.charAt(0) : '?';
    }

    this.shadowRoot.querySelector('.customer-name').textContent = this.dataset.name || 'Anonymous';

    const verifiedElement = this.shadowRoot.querySelector('.customer-verified');

    const score = parseFloat(this.dataset.score);
    const customerRating = this.shadowRoot.querySelector<HTMLElement>('.customer-rating');

    if (score > 0) {
      const ratingStarsElement = this.shadowRoot.querySelector('.product-rating-stars');
      ratingStarsElement.setAttribute('style', `--rating: ${score};`);
      verifiedElement.textContent = 'Verified Buyer';

      const ratingValueElement = this.shadowRoot.querySelector('.product-rating-value');
      ratingValueElement.textContent = score.toFixed(1);

      customerRating.setAttribute('aria-label', [score.toFixed(0), 'out of 5 stars'].join(' '));
      customerRating.classList.remove('hidden');
    } else {
      customerRating.classList.add('hidden');
    }

    if (this.dataset.content) {
      const questionElement = this.shadowRoot.querySelector('.customer-question');

      // Yotpo Q&A content may contain HTML tags and HTML entities. We want HTML entities to render
      // correctly. However, this content is user-generated so it is fundamentally unsafe. We cannot
      // use innerHTML. So, instead, we manually encode entities and set text content.

      if (this.dataset.type === 'question') {
        questionElement.textContent = 'Q: ' + decode(this.dataset.content);
      } else if (this.dataset.type === 'answer') {
        // TODO: we need to not use innerHTML here, but that is tricky to do because we want to be
        // able to show links in text. This is probably acceptable for now given that only answers
        // provided by Integra employees are allowed but this is not the right way to implement.
        // What we need to do is change linkify to produce an array of text nodes and element nodes
        // where the only element nodes are the links, and then append those nodes one by one.
        questionElement.innerHTML = 'A: ' + linkify(decode(this.dataset.content));
      } else if (this.dataset.type === 'review') {
        questionElement.textContent = decode(this.dataset.content);
      }

      questionElement.classList.remove('hidden');
      verifiedElement.textContent = 'Verified Reviewer';
    }

    if (this.dataset.verified === 'true') {
      verifiedElement.classList.remove('hidden');
    }
  }
}

function linkify(text: string) {
  const urlRegex =
    /(\b(https:\/\/|langehair.com)[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
  return text.replace(urlRegex, function (url) {
    return '<a href="' + url + '" target="_blank">' + url + '</a>';
  });
}

declare global {
  interface HTMLElementTagNameMap {
    'yotpo-avatar': YotpoAvatar;
  }
}

if (!customElements.get('yotpo-avatar')) {
  customElements.define('yotpo-avatar', YotpoAvatar);
}
