/**
 * Initialized on first call to encodeEntities, then re-used
 */
let element: HTMLTextAreaElement;

/**
 * Encodes HTML entities in a mostly safe manner but at the expense of touching the dom.
 */
export function decode(input: string) {
  if (!element) {
    element = document.createElement('textarea');
  }

  element.innerHTML = input;

  const output = element.value;

  // avoid leaving around stuff in dom indefinitely, even when detached
  element.innerHTML = '';

  return output;
}
